import { color } from '../theme/color';
import { PageQuery_page_name } from '../__generated__/graphql';
// TODO ugly!!!!!!
export const getBottomColors = (name: PageQuery_page_name | null) =>
  name?.sv === 'Om oss' || name?.en === 'About us'
    ? color.orange
    : name?.sv === 'Case' || name?.en === 'Case'
    ? color.green
    : name?.sv === 'Stuff' || name?.en === 'Stuff'
    ? color.blue
    : name?.sv === 'Jobb' || name?.en === 'Jobs'
    ? color.red
    : name?.sv === 'Esatto Play' || name?.en === 'Esatto Play'
    ? color.white
    : color.pink;
